
import React, {useState} from 'react'
import { connect } from 'react-redux'
import ViewThumb from '../image/ViewThumb'
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';

const styles = {
  container: {
    display:'flex', 
    width:'100%',
    zIndex:0,
  },
  imageContainer:{
    marginLeft:'auto', 
    marginRight:'auto',
    zIndex:0,
  },
  image: (im) =>({
    maxHeight:im.expand?'100vh':'65vh',
    transform: im.rotate?'rotate(' + im.rotate + 'deg)':null,
    transition:im.rotate?'500ms all ease':null,
    zIndex:0,
  }),
}

const Function = ({list, setList, edit}) => {
    const [startIndex, setStartIndex] = useState(0);
    const [expand, setExpand] = useState(undefined);
    const extend = list.length > 12
    const first = startIndex===0
    const last = list.length - startIndex  <= 11
    const offset = !extend?12:first?11:last?11:10
    const newList = list.find((it, index) => (index >= startIndex && index < startIndex + offset && it.open))?list
          :[...list.slice(0, startIndex), {...list[startIndex], open:true}, ...list.slice(startIndex + 1)]
    const className = edit?'column is-4-mobile is-3-tablet is-2-desktop is-1-widescreen':'column is-4-mobile is-3-tablet is-2-desktop is-1-widescreen'
    const previous = () => {setStartIndex(Math.max(startIndex-offset, 0))
                            setList(list.map(it => ({...it, open:undefined})))}
    const next = () => {setStartIndex(startIndex + 10 > list.length?startIndex:startIndex + offset) 
                        setList(list.map(it => ({...it, open:undefined})))}
    const handleOpen = (ix) => setList(list.map((it, index)=> ({...it, open:ix===index?list[ix].open?undefined:true:undefined})))
    const toggleExpand = () => setExpand(expand?undefined:true)
    const openIndex = newList.findIndex(it=>it.open)
    const openImage = {...newList[openIndex], expand}
      
    console.log('list (View Images:', list)
    return(
      <div>
        <div className='columns is-multiline is-mobile is-centered'>
          {(extend && !first)?
            <div className={className} >
              <div className="button is-light" onClick={previous}>
                <NavigateBeforeIcon />
              </div>
            </div>
          :null}
          {newList.map((im, ix) => 
              (ix >= startIndex && ix < startIndex + offset) ?
                <div className={className}>
                  <ViewThumb 
                    edit={edit}
                    index={ix}
                    list={newList} 
                    setList={setList}
                  />
                </div>  
              :null  
          )}
          {(extend && !last)?
            <div className={className} >
              <div className="button is-light" onClick={next}>
                  <NavigateNextIcon />
              </div>
            </div>
          :null}
        </div>  
        {openImage?
          <div style={styles.container}>
            <div style = {styles.imageContainer} onClick={()=>toggleExpand()}>
              <img
                style={styles.image(openImage)}              
                src = {`${openImage.src?openImage.src:openImage.thumbSrc}?${openImage.mdate}`} 
                alt={openImage.src?openImage.src:openImage.thumbSrc} 
              />
            </div>
          </div>
        :null}  
      </div>
    )    
}          
// <img className='column' src={it.src} height={160} alt={it.src}/>

const mapStateToProps = state => ({
  edit:state.navbar.edit,
})

export default connect(mapStateToProps, null)(Function)


    