import React from "react"
import { connect } from 'react-redux';
import Layout from "../components/layout"
import SEO from "../components/seo"
import LayoutImages from "../components/image/LayoutImages"
import RowView from "../components/view/rowView"
import EditMenu from "../components/image/EditMenu"
import MenuSubdir from "../components/image/MenuSubdir"
import {NAVBAR_KEY_ROOTDIR} from '../state/navbar';


const Page = ({rootdir}) => {
  const title = rootdir.replace(/^.*[/]/, '')
  return(
  <Layout>
    <SEO title={title} />
    <LayoutImages>
      <EditMenu />
      <MenuSubdir />
      <RowView />
    </LayoutImages>    
  </Layout>
)}  

//<button className="button is-light" onClick={toggleEdit}>Light</button>

const mapStateToProps = state => ({
  rootdir:state.navbar[NAVBAR_KEY_ROOTDIR],
})

export default connect(mapStateToProps, null)(Page)

